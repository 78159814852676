p {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
}
.navbar {
  font-weight: 700;
  box-shadow: 0 5px 20px -10px #efe1e1;
}
.navbar .navbar-nav .nav-link {
  color: #000000;
}
.navbar .navbar-nav .nav-link:hover {
  color: #000000;
}
.navbar .navbar-nav .nav-item {
  position: relative;
}
.navbar .navbar-nav .nav-item::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #2196f3;
  width: 0%;
  content: "";
  height: 3px;
  transition: all 0.5s;
}

.navbar .navbar-nav .nav-item:hover::after {
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .navbar {
    padding-left: 10%;
    padding-right: 10%;
  }
  .navbar .navbar-brand {
    padding: 0 0.7em;
  }
  .navbar .navbar-nav .nav-link {
    padding: 1em 0;
  }
  .navbar .navbar-nav .nav-item {
    margin: 0 1em;
  }
}
.navbar .navbar-nav .nav-item::after {
  transition: all 0.5s;
}

.iconContact {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #2196f3;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.iconContact:hover {
  background: #bc4d8e;
}
.HeadingBanner {
  height: calc(100vh - 80px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  animation: changeBg 8s infinite;
}
@keyframes changeBg {
  0%,
  25% {
    background-image: url("./Images/First.JPG");
  }
  50% {
    background-image: url("./Images/Fourth.JPG");
  }
  75% {
    background-image: url("./Images/Second.jpg");
  }
  100% {
    background-image: url("./Images/Fifth.JPG");
  }
}
.Headtxtbanner {
  padding-top: 20%;
}
.bannerFont {
  font-weight: bolder;
  font-size: 3rem;
  color: #bc4d8e;
  line-height: 3.5rem;
  padding: 0.25rem 0.75rem;
  margin-left: 1rem;
  display: inline-block;
  background-color: #4fc3f7;
}

.Head2 {
  font-weight: bolder;
  font-size: 2rem;
}

.ullist {
  list-style-type: none;
}
.lilist {
  display: inline-block;
  padding-left: 2%;
  font-weight: bolder;
  font-size: 1.8rem;
  color: #444444;
}

.borderdata {
  border-top: 1px solid #a1a0a0;
}

.conactContainer {
  height: calc(100vh - 200px);
}
/* Banner Animation */
.animation {
  margin-left: 1rem;
}
.animText {
  font-size: 2.8rem;
  color: white;
}
.animation > div > div {
  padding: 0.25rem 0.75rem;
  display: inline-block;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

.first div {
  background-color: #cfcece;
}
.second div {
  background-color: #4fc3f7;
}
.third div {
  background-color: #ffb74d;
}
.serviceBgs {
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.limg {
  width: 2rem;
  height: 2rem;
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;
}
.filterBg {
  backdrop-filter: brightness(40%);
  height: 100%;
  font-size: large;
  font-weight: 800;
  color: #c9c6c6;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.filterBg:hover {
  color: #ffffff;
  backdrop-filter: brightness(30%);
  animation: mytxt 0.6s 1;
}

@keyframes mytxt {
  from {
    font-size: medium;
  }
  to {
    font-size: large;
  }
}

.serviceBgs > a {
  text-decoration: none;
}

.cardBlocks {
  transition: 0.2s;
  animation: mymove 0.6s 1;
}
.cardBlocks:hover,
.cardBlocks:focus-within {
  transform: translateY(-1rem);
}

@keyframes mymove {
  from {
    transform: translateY(-2rem);
  }
  to {
    transform: translateY(0rem);
  }
}
.whatsicon {
  background-color: #99959559;
  padding: 10px;
  z-index: 5;
  border-radius: 20px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
@media only screen and (max-width: 600px) {
  .phoneTxt {
    flex-direction: column;
  }
  .vd {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
